// TODO: This should be done as cloud functions instead

/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/firestore';
// import unionWith from 'lodash/unionWith'
import merge from 'lodash/merge';
import { postSlackMessageGame } from '@/api/slack';
import { getCollectionPath } from '@/utils/dataPathResolver'

const higschoreEntryCount = Infinity; //10; // number of entries we store in the highscore

/**
 * Submit game steps (transaction)
 * 1. read all player docs & read game stats doc (create if it does not exist)
 * 2. create game entry
 * 3. update player docs -> doubles, singles, latestGame, opponents, partners
 * 4. create or update games stats document. Compare current data with updated data for players of the submitted game -> higscores (wins, losses, points, winRatio for doubles & singles), total games (+1), doubles (total, withScore, points), singles (total, withScore, points)
 */
const submitGame = async (game) => {
  const gameData = {
    ...game,
    players: [...game.homeTeam, ...game.awayTeam],
    created: firebase.firestore.FieldValue.serverTimestamp(),
  };

  const isSingles = gameData.players.length === 2;
  const homePoints = gameData.homeScore.reduce((a, b) => a + b, 0);
  const awayPoints = gameData.awayScore.reduce((a, b) => a + b, 0);
  const totalPoints = homePoints + awayPoints;
  const hasScore = totalPoints > 0;

  const db = firebase.firestore();
  const gamesStatsRef = db.collection(getCollectionPath('stats')).doc('games');
  const newGameDocRef = db.collection(getCollectionPath('games')).doc();

  const result = await db.runTransaction(async (transaction) => {
    try {
      // 1. read player docs and game stats doc
      const gamesStatsDoc = await transaction.get(gamesStatsRef);

      // TODO: Do this inline in pt 3 instead?
      const playerDocs = [];
      for (const player of gameData.players) {
        const playerRef = db.collection(getCollectionPath('players')).doc(player.id);
        const doc = await transaction.get(playerRef);

        playerDocs.push({
          id: player.id,
          doc,
        });
      }

      // 2. Create the game entry
      transaction.set(newGameDocRef, gameData);

      const updatedPlayerData = [];

      // 3. Update player entries
      for (const player of playerDocs) {
        const playerData = player.doc.data();
        const isHome = gameData.homeTeam.some((e) => e.id === player.id); // gameData.homeTeam.includes(player.id);
        const gameType = isSingles ? 'singles' : 'doubles';
        const playerWon =
          (isHome && gameData.winner === 'home') ||
          (!isHome && gameData.winner === 'away');
        const playerPoints = isHome ? homePoints : awayPoints;
        const opponentPoints = isHome ? awayPoints : homePoints;
        let partners = isHome ? gameData.homeTeam : gameData.awayTeam;
        partners = partners.filter((item) => {
          return item.id != player.id;
        });
        const opponents = isHome ? gameData.awayTeam : gameData.homeTeam;

        // Data to be updated
        const updatedData = {
          latestGame: {
            id: newGameDocRef.id,
            played: firebase.firestore.FieldValue.serverTimestamp(),
          },
          opponents: playerData.opponents,
          [gameType]: playerData[gameType],
        };
        if (!isSingles) {
          updatedData.partners = playerData.partners;
          partners.forEach((partner) => {
            const index = updatedData.partners.findIndex(
              (item) => item.id === partner.id
            );
            if (index > -1) {
              //update
              updatedData.partners[index].count += 1;
            } else {
              //add
              updatedData.partners.push({
                ...partner,
                count: 1,
              });
            }
          });
        }

        opponents.forEach((opponent) => {
          const index = updatedData.opponents.findIndex(
            (item) => item.id === opponent.id
          );
          if (index > -1) {
            //update
            updatedData.opponents[index].count += 1;
          } else {
            //add
            updatedData.opponents.push({
              ...opponent,
              count: 1,
            });
          }
        });

        updatedData[gameType].played += 1;
        updatedData[gameType].won += playerWon ? 1 : 0;
        updatedData[gameType].pointsWon += playerPoints;
        updatedData[gameType].pointsLost += opponentPoints;

        // Merge playerdocs data with updated data to get a full updated version of the document
        // Are we not getting that already? Worth investigating, if so we can just read doc.data()...
        // TODO: Better way to do this? It seems that reading data from doc after update still returns the old data. matybe we need to do annother get
        // At least by not reading it we save calls against our quota...
        const merged = {};
        merge(merged, player.doc.data(), updatedData);
        updatedPlayerData.push({
          id: player.id,
          data: merged,
        });

        transaction.update(
          db.collection(getCollectionPath('players')).doc(player.id),
          updatedData
        );
      }

      // 4. Update game stats
      // makes lists with the participants in the game with their current hisghcore stats
      let highscoreGames = updatedPlayerData
        .map((player) => {
          const id = player.id;
          const data = player.data; // player.doc.data() // This gets us the current state before update :-(
          return {
            id,
            displayName: data.displayName,
            photoURL: data.photoURL,
            value: data.singles.played + data.doubles.played,
          };
        })
        .sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );

      let highscoreWins = updatedPlayerData
        .map((player) => {
          const id = player.id;
          const data = player.data; // player.doc.data() // This gets us the current state before update :-(
          return {
            id,
            displayName: data.displayName,
            photoURL: data.photoURL,
            value: data.singles.won + data.doubles.won,
          };
        })
        .sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );

      let highscorePoints = updatedPlayerData
        .map((player) => {
          const id = player.id;
          const data = player.data; // player.doc.data() // This gets us the current state before update :-(
          return {
            id,
            displayName: data.displayName,
            photoURL: data.photoURL,
            value: data.singles.pointsWon + data.doubles.pointsWon,
          };
        })
        .sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );

      let highscoreRatio = updatedPlayerData
        .map((player) => {
          const id = player.id;
          const data = player.data; // player.doc.data() // This gets us the current state before update :-(
          const played = data.singles.played + data.doubles.played;
          const won = data.singles.won + data.doubles.won;
          const ratio = played > 0 ? won / (played * 1.0) : 0;
          return {
            id,
            displayName: data.displayName,
            photoURL: data.photoURL,
            value: ratio,
          };
        })
        .sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );

      if (gamesStatsDoc.exists) {
        const gameStats = gamesStatsDoc.data();
        // Add items from gamestats hiogscores that do not already exist in the local highscores list. The local list has dat aincluding the game that was just played, so it is newer
        gameStats.highscoreGames.forEach((highscore) => {
          if (!highscoreGames.some((item) => item.id === highscore.id)) {
            highscoreGames.push(highscore);
          }
        });
        // sort the combined list
        highscoreGames.sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );

        // caps list to a length of higschoreEntryCount
        highscoreGames.splice(higschoreEntryCount, Infinity);

        gameStats.highscoreWins.forEach((highscore) => {
          if (!highscoreWins.some((item) => item.id === highscore.id)) {
            highscoreWins.push(highscore);
          }
        });
        highscoreWins.sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );
        highscoreWins.splice(higschoreEntryCount, Infinity);

        gameStats.highscorePoints.forEach((highscore) => {
          if (!highscorePoints.some((item) => item.id === highscore.id)) {
            highscorePoints.push(highscore);
          }
        });
        highscorePoints.sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );
        highscorePoints.splice(higschoreEntryCount, Infinity);

        gameStats.highscoreRatio.forEach((highscore) => {
          if (!highscoreRatio.some((item) => item.id === highscore.id)) {
            highscoreRatio.push(highscore);
          }
        });
        highscoreRatio.sort(
          (a, b) =>
            b.value - a.value || a.displayName.localeCompare(b.displayName)
        );
        highscoreRatio.splice(higschoreEntryCount, Infinity);

        transaction.update(gamesStatsRef, {
          totalGames: firebase.firestore.FieldValue.increment(1),
          totalPoints: firebase.firestore.FieldValue.increment(totalPoints),
          latestGame: {
            id: newGameDocRef.id,
            played: firebase.firestore.FieldValue.serverTimestamp(),
          },
          ['doubles.gamesWithScore']: firebase.firestore.FieldValue.increment(
            isSingles ? 0 : hasScore ? 1 : 0
          ),
          ['doubles.totalGames']: firebase.firestore.FieldValue.increment(
            isSingles ? 0 : 1
          ),
          ['doubles.totalPonts']: firebase.firestore.FieldValue.increment(
            isSingles ? 0 : totalPoints
          ),
          ['singles.gamesWithScore']: firebase.firestore.FieldValue.increment(
            isSingles ? (hasScore ? 1 : 0) : 0
          ),
          ['singles.totalGames']: firebase.firestore.FieldValue.increment(
            isSingles ? 1 : 0
          ),
          ['singles.totalPonts']: firebase.firestore.FieldValue.increment(
            isSingles ? totalPoints : 0
          ),
          highscoreGames,
          highscoreWins,
          highscorePoints,
          highscoreRatio,
        });
      } else {
        highscoreGames.splice(higschoreEntryCount, Infinity);
        highscoreWins.splice(higschoreEntryCount, Infinity);
        highscorePoints.splice(higschoreEntryCount, Infinity);
        highscoreRatio.splice(higschoreEntryCount, Infinity);

        transaction.set(gamesStatsRef, {
          totalGames: 1,
          totalPoints: totalPoints,
          latestGame: {
            id: newGameDocRef.id,
            played: firebase.firestore.FieldValue.serverTimestamp(),
          },
          doubles: {
            gamesWithScore: isSingles ? 0 : hasScore ? 1 : 0,
            totalGames: isSingles ? 0 : 1,
            totalPonts: isSingles ? 0 : totalPoints,
          },
          singles: {
            gamesWithScore: isSingles ? (hasScore ? 1 : 0) : 0,
            totalGames: isSingles ? 1 : 0,
            totalPonts: isSingles ? totalPoints : 0,
          },
          highscoreGames,
          highscoreWins,
          highscorePoints,
          highscoreRatio,
        });
      }

      postSlackMessageGame(gameData);

      return true;
    } catch (error) {
      console.error(
        'submitGame ERROR',
        error.code,
        error.name,
        error.message,
        JSON.stringify(error, Object.getOwnPropertyNames(error))
      );
      return false;
    }
  });

  return result;
};

//#endregion

export { submitGame };
