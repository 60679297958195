<template>
  <div class="play">
    <header>
    <h1>Submit a Game Result</h1>
    <p>This is where you submit the results of a game. <strong>Only one player</strong> needs to submit the game by filling in the form below</p>
    </header>
    <template v-if="playerStore.isLoggedIn && statsStore.allPlayers.length > 1">
      <template v-if="busy">
        <section class="busy">
          <h2>Saving Game...</h2>
          <p>Please wait while your data is beeing saved</p>
        </section>
      </template>
      <template v-else-if="submitReceipt">
        <section class="receipt">
          <h2>Game submitted!</h2>
          <p>Great suff, now get back to work...</p>
          <button v-on:click="submitConfirm">Will do</button>
        </section>
      </template>
      <template v-else-if="submitError">
        <section class="error">
          <h2>Failed to submit game :-(</h2>
          <p>There was an error when submitting your game</p>
          <button v-on:click="submitConfirm">Try again</button>
        </section>
      </template>
      <template v-else>
        <section class="teams">
          <h2>Who played?</h2>
          <div class="your-team">
            <h3>You(r team)</h3>
            <div class="input-row">
              <label value="false" for="player1">You: </label>
              <input :value="playerStore.state.user.displayName"  class="required" disabled/>
            </div>
            <div class="input-row">
              <label for="player2">Your partner (optional):</label>
              <select v-model="player2" id="player2" class="optional">
                <option value="false" selected>None</option>
                <option v-for="(user) in usersExcludingCurrent" :value="{ id: user.id, displayName: user.displayName }" :key="user.id">
                  {{user.displayName}}
                </option>
              </select>
            </div>
          </div>
          <div class="opponents">
            <h3>Opponent(s)</h3>
            <div class="input-row">
              <label for="player3">Player 3:</label>
              <select v-model="player3" id="player3" class="required">
                <option value="false" selected disabled>None</option>
                <option v-for="(user) in usersExcludingCurrent" :value="{ id: user.id, displayName: user.displayName }" :key="user.id">
                  {{user.displayName}}
                </option>
              </select>
            </div>
            <div class="input-row">
              <label for="player4">Player 4 (optional):</label>
              <select v-model="player4" id="player4" class="optional">
                <option value="false" selected>None</option>
                <option v-for="(user) in usersExcludingCurrent" :value="{ id: user.id, displayName: user.displayName }" :key="user.id">
                  {{user.displayName}}
                </option>
              </select>
            </div>
          </div>
        </section>
        <section class="result">
          <h2>Who won?</h2>
          <div class="input-row">
            <label for="winner">Winner:</label>
            <select v-model="winner" id="winner" >
              <option selected disabled value="false">Please select</option>
              <option value="home">You</option>
              <option value="away">Your opponent</option>
            </select>
          </div>
        </section>
        <section class="score">
          <h2>What was the score (optional)</h2>
          <div class="input-row">
            <label for="sets">Number of sets played (optional):</label>
            <select v-model.number="sets" @change="trimScoreToSetCount" id="sets" >
              <option selected value="0">Skip score</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
          <div v-if="sets > 0">
            <p>Please put <strong>your score first</strong> in each set</p>
            <ul>
              <li v-for="(n,index) in sets" :key="index" class="input-row">
                <span>Set {{n}} </span>
                <div class="input-row">
                  <select :id="`set-${index}-home`" v-model="homeScore[index]">
                    <option v-for="(n, i) in 31" :value="i" :key="i">{{i}}</option>
                  </select>
                  <select :id="`set-${index}-away`" v-model="awayScore[index]">
                    <option v-for="(n, i) in 31" :value="i" :key="i">{{i}}</option>
                  </select>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="submit">
          <p class="error" v-if="validationMessage"><strong>{{validationMessage}}</strong></p>
          <button v-on:click="submit">Sumbit result</button>
        </section>
      </template>
    </template>
    <template v-else>
      <sign-in />
      <p v-if="playerStore.isLoggedIn && statsStore.allPlayers.length <= 1">Seems you are the only registered player - <strong>tell your collegues to join!</strong></p>
    </template>
    
  </div>
</template>

<script>
// TODO: Use validation library here?
  import { computed } from "vue";
  import { statsStore } from '@/store/statsStore'
  import { playerStore } from '@/store/playerStore'
  import SignIn from '@/components/SignIn'
  import { submitGame } from '@/api'
  
  export default {
    inject: ['statsStore', 'playerStore'],
    components: {
      'sign-in': SignIn,
    },
    data() {
    return {
      player2: false,
      player3: false,
      player4: false,
      winner: false,
      sets: 0,
      homeScore: [0,0,0,0,0],
      awayScore: [0,0,0,0,0],
      validationMessage: false,
      submitReceipt: false,
      submitError: false,
      busy: false,
    };
  },
  setup() {
    const usersExcludingCurrent = computed(() => {
      const users = statsStore.allPlayers.filter(user => user.id !== playerStore.state.user.uid);
      users.sort((a, b) => { return  a.displayName.toUpperCase() > b.displayName.toUpperCase()} )
      return users;
    })

    return { usersExcludingCurrent }
  },
  methods: {
    trimScoreToSetCount() {
      this.homeScore = this.homeScore.slice(0, this.sets);
      this.awayScore = this.awayScore.slice(0, this.sets);
      while(this.homeScore.length < this.sets) {
        this.homeScore.push(0)
      }
      while(this.awayScore.length < this.sets) {
        this.awayScore.push(0)
      }
    },
    resetForm() {
      this.player2 = false;
      this.player3 = false;
      this.player4 = false;
      this.winner = false;
      this.sets = 0;
      this.homeScore = [0,0,0,0,0];
      this.awayScore = [0,0,0,0,0];
      this.validationMessage = false;
      this.submitReceipt = false;
      this.submitError = false;
    },
    validateForm() {
      // you
      if(!playerStore.isLoggedIn) {
        return false
      }

      // opponent
      if(this.player3 == false) {
        this.validationMessage = 'Please select an opponent'
        return false
      }

      // doubles. Make sure both teams have doubles players registered
      if((this.player2 == false && this.player4 != false) || (this.player2 != false && this.player4 == false)) {
        this.validationMessage = 'Make sure that both teams have the same number of players'
        return false
      }

      // make sure the same player is not represented multiple times
      const players = [
        playerStore.state.user.uid,
        this.player2 ? this.player2.id : false,
        this.player3 ? this.player3.id : false,
        this.player4 ? this.player4.id : false,
      ].filter(item => item !== false && item !== 'false')

      if(players.length !== new Set(players).size) {
        this.validationMessage = 'You have selected the same player multiple times'
        return false
      }

      if(this.winner == false) {
        this.validationMessage = 'You must select a winner'
        return false
      }

      // If submitting score, make sure it is valid
      // Make sure the set score matches the winner
      if(this.sets > 0) {
        let setWins = {
          home : 0,
          away: 0,
        }
        for(let i = 0; i < this.sets; i++) {
          // both away and home cannot be 0
          if(this.homeScore[i] === 0 && this.awayScore[i] === 0) {
            this.validationMessage = 'Set score cannot be 0-0'
            return false
          }
          // home and away cant be the same
          if(this.homeScore[i] === this.awayScore[i]) {
            this.validationMessage = 'Set score cannot be the same for both teams'
            return false
          }
          // set winner
          if(this.homeScore[i] > this.awayScore[i]) {
            setWins.home ++
          } else {
            setWins.away ++
          }
        }

        // score does not match winner
        if((this.winner == 'home' && setWins.home < setWins.away) ||
          (this.winner == 'away' && setWins.away < setWins.home)) {
            this.validationMessage = 'Assigned winner does not match the set score?'
            return false
        }
      }

      this.validationMessage = false;
      return true
    },

    async submit() {
      if(!this.validateForm()) {
        return
      }

      this.trimScoreToSetCount()
      
      const homeTeam =  [
        {
          id: playerStore.state.user.uid,
          displayName: playerStore.state.user.displayName,
        },
        Object.assign({}, this.player2),
      ].filter(item => item !== false && item !== 'false' && Object.keys(item).length !== 0)

      const awayTeam =  [
        Object.assign({}, this.player3),
        Object.assign({}, this.player4),
      ].filter(item => item !== false && item !== 'false' && Object.keys(item).length !== 0)

      const gameData = {
        homeTeam,
        awayTeam,
        winner: this.winner,
        homeScore: [...this.homeScore],
        awayScore: [...this.awayScore],
      };

      this.busy = true
      const success = await submitGame(gameData)
      this.busy = false

      if(success) {
        this.resetForm();
        this.submitReceipt = true;
      } else {
        this.submitError = true;
      }
    },
    submitConfirm() {
      this.submitReceipt = false;
      this.submitError = false;
    },
  }
}
</script>

<style scoped lang="scss">
.score {
  ul {
    list-style: none;
  }
}
.input-row {
  display: flex;

  & > * {
    flex: 1 1 0px
  }
}

section.submit {
  text-align: center;
  margin-top: 30px;
}

.error {
  color: #e92566;
}
</style>